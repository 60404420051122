import React from "react";
import jQuery from "jquery";

const config = require("config");
var wpcf7Loader;
if (typeof window !== `undefined`) {
  wpcf7Loader =
    require("../../../node_modules/contact-form-7/includes/js/src/index.js").default;
} else {
  wpcf7Loader = () => {};
}

interface FormProps {
  data: {
    contact: {
      content: string;
    };
  };
  formRef?: React.Ref<HTMLDivElement>;
}
interface FormState {
  isLoaded: boolean;
  wpcf7Loaded: boolean;
  page: string;
}
interface WPCF7 {
  init: (x: any) => void;
}
class Form extends React.Component<FormProps, FormState> {
  private wpcf7: WPCF7 | false;
  constructor(props: FormProps) {
    super(props);
    if (typeof window !== `undefined`) {
      this.wpcf7 = {
        /* @ts-ignore */
        api: {
          namespace: "contact-form-7/v1",
          root: config.wpcf7_api,
        },
      };
      this.wpcf7 = wpcf7Loader(this.wpcf7);
      if (window) {
        window.wpcf7 = this.wpcf7;
      }
      /* @ts-ignore */
    } else {
      this.wpcf7 = false;
    }

    console.log(this.props);

    let response = this.props.data.contact.content;

    let pattern =
      /<span class="wpcf7-form-control-wrap ([a-z-]*)">(<(?:input|textarea) .*)<\/span><span class="label">(.*)<\/span>/g;
    let result = response.replace(
      pattern,
      `
    <span class="wpcf7-form-control-wrap $1">
        $2
        <span class="label">$3</span>
        <span class="border"></span>
    </span>
    `
    );
    this.state = {
      isLoaded: false,
      wpcf7Loaded: false,
      page: result, //responseEl.outerHTML
    };
  }

  componentDidMount() {
    if (!this.wpcf7 || this.state.wpcf7Loaded) {
      return;
    }
    const wpcf7 = this.wpcf7;

    if (typeof document !== "undefined") {
      document.querySelectorAll(".wpcf7 > form").forEach((form) => {
        wpcf7.init(form);
        const closestWpcf7 = form.closest(".wpcf7");
        if (closestWpcf7 != null) closestWpcf7.classList.replace("no-js", "js");
      });
      this.setState({ ...this.state, wpcf7Loaded: true });
    }
  }

  render() {
    const { page } = this.state;
    return (
      <div>
        <div className="box-wrapper">
          <div
            ref={this.props.formRef}
            dangerouslySetInnerHTML={{ __html: page }}
            className="contact-container box"
          ></div>
        </div>
      </div>
    );
  }
}

export default Form;

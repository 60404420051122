import { graphql, navigate, PageProps } from "gatsby";
import React, { RefObject, useEffect, useRef, useState } from "react";
import Form from "../../components/Form";
import { addOrder } from "../../components/yeasts/PreviousOrders";
import {
  computeYeastPrice,
  OptionalOrder,
} from "../../components/yeasts/utils";

interface OrderPortalProps extends PageProps<OrderData> {}
const OrderPortal: React.FC<OrderPortalProps> = ({ data, location }) => {
  if (typeof window !== `undefined`) {
    const goBack = () => {
      navigate("../", { replace: true });
      return <div></div>;
    };

    if (location.state == null) {
      return goBack();
    }
    /* @ts-ignore */
    const order = JSON.parse(location.state.order) as OptionalOrder;

    if (
      order.yeast === undefined ||
      order.yeast.id === undefined ||
      order.yeast.meta.price === undefined
    ) {
      return goBack();
    }
    return <Order data={data} order={order} />;
  } else {
    return <div></div>;
  }
};
interface OrderData {
  contact: {
    title: string;
    content: string;
  };
}
interface OrderProps {
  order: OptionalOrder;
  data: OrderData;
}
const Order: React.FC<OrderProps> = ({ data, order }) => {
  const formEl: RefObject<HTMLDivElement | null> = useRef(null);

  const [qty, setQty] = useState(order.quantity);
  function calculate_price() {
    const newQty = parseInt(
      formEl.current.querySelector<HTMLInputElement>("#qty").value,
      10
    );
    setQty(newQty);

    const computedPrice = computeYeastPrice(order.yeast, newQty);
    const priceEl: HTMLElement =
      formEl.current.querySelector<HTMLDivElement>(".qty-price .price");
    if (computedPrice) {
      priceEl.querySelector(".value").textContent = computedPrice.toFixed(2);

      priceEl.style.display = "initial";
    } else {
      priceEl.style.display = "none";
    }
  }
  useEffect(() => {
    const el = formEl.current;
    if (el != null) {
      const qtyEl = el.querySelector<HTMLInputElement>("#qty");
      if (qtyEl == null) return;
      /* @ts-ignore */
      if (qty != false) {
        qtyEl.value = qty.toString();
      }
      qtyEl.addEventListener("change", calculate_price);
      const yeastSelector = el.querySelector<HTMLInputElement>("#yeast")!;
      yeastSelector.value = order.yeast.title;
      yeastSelector.readOnly = true;
      calculate_price();
    }
  }, []);
  useEffect(() => {
    const el = formEl.current;
    if (el != null) {
      const form = el.querySelector(".wpcf7");
      const addOrderCallback = function () {
        if (qty != false)
          addOrder({
            yeast: order.yeast,
            quantity: qty,
          });
      };

      form.addEventListener("wpcf7mailsent", addOrderCallback);
      return () => form.removeEventListener("wpcf7mailsent", addOrderCallback);
    }
  }, [qty]);
  return <Form formRef={formEl} data={data} />;
};

/* @ts-ignore */
OrderPortal.title = "Commande";
export default OrderPortal;
export const query = graphql`
  query Order {
    contact: wpPage(slug: { eq: "order" }) {
      id
      title
      content
    }
  }
`;
